@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.zpgl {
  width: 100%;
  height: 100%;
  .row1 {
    display: flex;
    justify-content: space-between;
    .right {
      display: flex;
    }
  }
  .row2 {
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 58px);
    .row2-card {
      padding: 10px 0;
      height: 100%;
      overflow-y: scroll;
      .item {
        color: rgba(51, 51, 51, 1);
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        border-radius: 4px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .item-index {
          margin-left: 10px;
          min-width: 18px;
          height: 18px;
          border-radius: 4px;
          border: 2px solid rgba(197, 192, 224, 1);
          color: rgba(197, 192, 224, 1);
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        }
        .item-title {
          margin-left: 10px;
          width: 25%;
        }
        .item-message {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 10px;
          width: 23%;
        }
        .item-other {
          width: 57%;
          display: flex;
          justify-content: space-around;
        }
        .item-audit {
          height: 20px;
          width: 80px;
          line-height: 20px;
          text-align: center;
          border-radius: 4px;
          font-size: 14px;
        }
        .item-check-true {
          background: #7f76bd;
          border-radius: 4px;
          color: #fff;
        }
        .item-check-false {
          color: #666;
          background: #ffffff;
          border: 1px solid #c5c0e0;
        }
        .item-date {
          color: rgba(153, 153, 153, 1);
          margin: 0 10px;
          width: 30%;
          text-align: center;
        }
        .item-sale {
          // width: 96px;
          height: 24px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #7f76bd;
          line-height: 24px;
          margin-left: 15px;
          text-align: right;
        }
        .item-state {
          margin-left: 15px;
          width: 54px;
          display: flex;
          justify-content: flex-end;
          .state {
            font-size: 14px;
            width: 54px;
            height: 20px;
            border-radius: 4px;
            color: #fff;
            text-align: center;
            line-height: 20px;
          }
        }
      }
    }
  }
  .row3 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .title {
    margin-right: 20px;
    width: 96px;
    height: 24px;
    background: #eceafa;
    border-radius: 4px;
    line-height: 24px;
    text-align: center;
  }
}
